import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Breadcrumb {
  name: string
  url?: string
}
export interface BreadcrumbState {
  items: Breadcrumb[]
}

export const initialBreadcrumbState: BreadcrumbState = {
  items: [],
}

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState: initialBreadcrumbState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
      state.items = action.payload
    },
  },
})

export const { setBreadcrumbs } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer
