import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { configureStore } from '@reduxjs/toolkit'
import headerSlice from './headerSlice'
import breadcrumbSlice from './breadcrumbSlice'
import referrerSlice from './referrerSlice'
import footerSlice from './footerSlice'
import errorSlice from './errorSlice'
import { apiService } from './apiService'
import headerOptionSlice from './headerOptionSlice'
import doneSlice from './doneSlice'

export const store = configureStore({
  reducer: {
    header: headerSlice,
    breadcrumb: breadcrumbSlice,
    referrer: referrerSlice,
    footer: footerSlice,
    error: errorSlice,
    headerOption: headerOptionSlice,
    done: doneSlice,
    [apiService.reducerPath]: apiService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware),
  devTools: process.env.APP_ENV === 'development',
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
