import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialReferrerState = {
  url: '',
}

export const referrerSlice = createSlice({
  name: 'referrer',
  initialState: initialReferrerState,
  reducers: {
    setReferrer: (state, action: PayloadAction<string>) => {
      state.url = action.payload
    },
  },
})

export const { setReferrer } = referrerSlice.actions
export default referrerSlice.reducer
