import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface HeaderOptionState {
  showRegist: boolean // 会員登録ボタン
  showLogin: boolean // ログインボタン
}

export const initialHeaderOptionState = {
  showRegist: true,
  showLogin: true,
}

export const headerOptionSlice = createSlice({
  name: 'headerOption',
  initialState: initialHeaderOptionState,
  reducers: {
    setHeaderOption: (state, action: PayloadAction<HeaderOptionState>) => {
      state.showRegist = action.payload.showRegist
      state.showLogin = action.payload.showLogin
    },
  },
})

export const { setHeaderOption } = headerOptionSlice.actions
export default headerOptionSlice.reducer
