import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialFooterState = {
  show: true,
}

export const footerSlice = createSlice({
  name: 'footer',
  initialState: initialFooterState,
  reducers: {
    setFooter: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload
    },
  },
})

export const { setFooter } = footerSlice.actions
export default footerSlice.reducer
