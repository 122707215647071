import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DoneState {
  doneTitle: string
  doneMessage: string
  buttons: React.ReactNode
}

export const initialDoneState = {
  doneTitle: '',
  doneMessage: '',
  buttons: null,
}

export const doneSlice = createSlice({
  name: 'done',
  initialState: initialDoneState,
  reducers: {
    setDone: (state, action: PayloadAction<DoneState>) => {
      state.doneTitle = action.payload.doneTitle
      state.doneMessage = action.payload.doneMessage
      state.buttons = action.payload.buttons
    },
    resetDone: (state) => {
      state.doneTitle = initialDoneState.doneTitle
      state.doneMessage = initialDoneState.doneMessage
      state.buttons = initialDoneState.buttons
    },
  },
})

export const { setDone, resetDone } = doneSlice.actions
export default doneSlice.reducer
