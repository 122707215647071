import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Modal {
  show: boolean
  class: string
  css: {
    zIndex: number
    display: string
    paddingLeft: string
  }
}

export const modalFadeIn: Modal = {
  show: true,
  class: 'modal fade in',
  css: { zIndex: 1050, display: 'block', paddingLeft: '0px' },
}
export const modalFadeOut: Modal = {
  show: false,
  class: 'modal fade',
  css: { zIndex: -1, display: 'block', paddingLeft: '0px' },
}

export interface Open {
  findSummary: boolean // 要約を探す
  features: boolean // 特集
  about: boolean // flierについて
  userMenu: boolean // ハンバーガーメニュー
  modalBackdrop: boolean // モーダル背景
  modalLoginForm: Modal // ログインモーダル
  modalLoginError: Modal // ログインエラーモーダル
}
export interface HeaderState {
  open: Open
  mobileHeader: boolean // モバイル用のヘッダー
}

export const initialHeaderState: HeaderState = {
  open: {
    findSummary: false,
    features: false,
    about: false,
    userMenu: false,
    modalBackdrop: false,
    modalLoginForm: modalFadeOut,
    modalLoginError: modalFadeOut,
  },
  mobileHeader: true,
}

export const headerSlice = createSlice({
  name: 'header',
  initialState: initialHeaderState,
  reducers: {
    setOpen: (state, action: PayloadAction<Open>) => {
      state.open = action.payload
    },
    setMobileHeader: (state, action: PayloadAction<boolean>) => {
      state.mobileHeader = action.payload
    },
  },
})

export const { setOpen, setMobileHeader } = headerSlice.actions

export default headerSlice.reducer
