import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ErrorState {
  errorTitle: string
  errorMessage: string
  buttons: React.ReactNode
}

export const initialErrorState = {
  errorTitle: '',
  errorMessage: '',
  buttons: null,
}

export const errorSlice = createSlice({
  name: 'error',
  initialState: initialErrorState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorState>) => {
      state.errorTitle = action.payload.errorTitle
      state.errorMessage = action.payload.errorMessage
      state.buttons = action.payload.buttons
    },
    resetError: (state) => {
      state.errorTitle = initialErrorState.errorTitle
      state.errorMessage = initialErrorState.errorMessage
      state.buttons = initialErrorState.buttons
    },
  },
})

export const { setError, resetError } = errorSlice.actions
export default errorSlice.reducer
