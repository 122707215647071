import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { store } from '@/stores/store'
import { CUSTOM_HEADER_FLIER_APP } from '@/utils/constant'

function fetcher<T>(url): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(process.env.BASE_PATH + url, {
      headers: {
        ...CUSTOM_HEADER_FLIER_APP,
      },
    })
      .then((response) => {
        return resolve(response.json())
      })
      .then((data) => reject(data))
  })
}

const swrOptions = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

interface Props {
  children?: React.ReactNode
}
export const AppProvider: React.FC<Props> = ({ children }) => {
  return (
    <Provider store={store}>
      <SWRConfig value={{ ...swrOptions, fetcher }}>{children}</SWRConfig>
    </Provider>
  )
}
